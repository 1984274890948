<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :allowed-dates="allowedMonths"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        class="px-1 pb-1"
        item-key="entry"
      >
        <template
          slot="headers"
          slot-scope="props"
        >
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="headerClass(header)"
              @click="changeSort(header.value)"
            >
              {{ header.text }}
              <v-icon
                v-if="header.sortable"
                small
              >
                arrow_upward
              </v-icon>
            </th>
          </tr>
        </template>
        <template
          slot="items"
          slot-scope="props"
        >
          <tr @click="props.expanded = !props.expanded">
            <td class="text-xs-left">
              {{ props.item.entry }}
            </td>
            <td class="text-xs-left">
              {{ props.item.user }}
            </td>
            <td class="text-xs-left">
              {{ props.item.dealer }}
            </td>
            <td class="text-xs-left">
              {{ props.item.pin }}
            </td>
            <td class="text-xs-right">
              {{ props.item.quantity }}
            </td>
            <td
              v-if="props.item.status === 'success'"
              class="text-xs-center"
            >
              <span class="success--text">Approved</span>
            </td>
            <td
              v-else-if="props.item.status === 'decline'"
              class="text-xs-center"
            >
              <span class="error--text">Declined</span>
            </td>
            <td
              v-else-if="props.item.status === 'pending'"
              class="text-xs-center"
            >
              <span class="warning--text">Pending</span>
            </td>
          </tr>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template
          slot="expand"
          slot-scope="props"
        >
          <v-card class="elevation-1 mx-2 mb-2 inner">
            <v-card-text>
              <v-container grid-list-xs>
                <v-layout
                  row
                  wrap
                >
                  <v-flex xs3>
                    <span class="primary--text">Name</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.name }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Msisdn</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.msisdn }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Purchase note</span>
                  </v-flex>
                  <v-flex xs9>
                    {{ props.item.purchaseNote }}
                  </v-flex>
                  <v-flex xs3>
                    <span class="primary--text">Amount</span>
                  </v-flex>
                  <v-flex xs9>
                    RM {{ props.item.amount }}
                  </v-flex>
                  <template v-if="props.item.status === 'pending'">
                    <v-flex xs3>
                      <span class="primary--text">Approver remark</span>
                    </v-flex>
                    <v-flex xs9>
                      <v-text-field
                        v-model="props.item.operatorRemark"
                        box
                      />
                    </v-flex>
                    <v-flex xs3>
&nbsp;
                    </v-flex>
                    <v-flex xs9>
                      <v-btn
                        slot="activator"
                        color="success"
                        @click="processOrder(props.item, true)"
                      >
                        <span>Approve&nbsp;</span>
                        <v-icon>check_circle</v-icon>
                      </v-btn>
                      <v-btn
                        slot="activator"
                        color="error"
                        @click="processOrder(props.item, false)"
                      >
                        <span>Decline&nbsp;</span>
                        <v-icon>cancel</v-icon>
                      </v-btn>
                    </v-flex>
                  </template>
                  <template v-else-if="props.item.status === 'success'">
                    <v-flex xs3>
                      <span class="primary--text">Received Pin</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.pin }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Received E-cash</span>
                    </v-flex>
                    <v-flex xs9>
                      RM {{ props.item.ecash }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Approver</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.operator }}
                    </v-flex>
                    <v-flex xs3>
                      <span class="primary--text">Approver remark</span>
                    </v-flex>
                    <v-flex xs9>
                      {{ props.item.operatorRemark }}
                    </v-flex>
                  </template>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>
        <template slot="footer">
          <td colspan="100%">
            <v-card flat>
              <v-card-title><span class="primary--text">Pin pricing</span></v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="pinPricingHeaders"
                  :items="pinPricingItems"
                  hide-actions
                  class="elevation-1"
                >
                  <template
                    slot="items"
                    slot-scope="props"
                  >
                    <td class="text-xs-center">
                      {{ props.item.plan }}
                    </td>
                    <td class="text-xs-center">
                      {{ props.item.mdPrice }}
                    </td>
                    <td class="text-xs-center">
                      {{ props.item.adPrice }}
                    </td>
                    <td class="text-xs-center">
                      {{ props.item.dealerPrice }}
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams, createPostParams } from '@/rest'

const reportTitle = 'Black Pin Orders'

export default {
  components: {
    Panel: () => import('@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      breadcrumbs: [
        {
          text: 'Purchase Orders', disabled: false, to: '/finance/purchase_orders',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      expand: true,
      headers: [
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Date',
          value: 'entry',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'User',
          value: 'user',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Purchaser',
          value: 'dealer',
        },
        {
          align: 'text-xs-left',
          sortable: true,
          text: 'Pin',
          value: 'pin',
        },
        {
          align: 'text-xs-right',
          text: 'Quantity',
          value: 'quantity',
        },
        {
          align: 'text-xs-center',
          text: 'Status',
          value: 'status',
        },
      ],
      pinPricingHeaders: [
        {
          align: 'text-xs-center',
          sortable: false,
          text: 'Plan',
          value: 'plan',
        },
        {
          align: 'text-xs-right',
          sortable: false,
          text: 'MD Price',
          value: 'mdPrice',
        },
        {
          align: 'text-xs-right',
          sortable: false,
          text: 'AD Price',
          value: 'adPrice',
        },
        {
          align: 'text-xs-right',
          sortable: false,
          text: 'Dealer Price',
          value: 'dealerPrice',
        },
      ],
      items: [],
      pinPricingItems: [],
      loading: false,
      menu: false,
      monthOf: null,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'name',
      },
      title: reportTitle,
    }
  },
  watch: {
    monthOf: function (val) {
      this.getBlackPinPurchases(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.monthOf = now.toFormat('yyyy-LL')
    this.getBlackPinPricing()
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getBlackPinPurchases (month) {
      const params = createGetParams({
        month: month,
      })
      this.loading = true
      this.$rest.get('getBlackPinOrders.php', params)
        .then(function (response) {
          this.items = response.data
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    getBlackPinPricing () {
      const params = createGetParams({})
      this.loading = true
      this.$rest.get('getBlackPinPricing.php', params)
        .then(function (response) {
          this.pinPricingItems = response.data
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    headerClass (header) {
      const headerClass = ['column']
      header.align && headerClass.push(header.align)
      header.sortable && headerClass.push('sortable')
      header.sortable && (this.pagination.descending
        ? headerClass.push('desc') : headerClass.push('asc'))
      header.sortable && header.value === this.pagination.sortBy &&
        headerClass.push('active')

      return headerClass
    },
    async processOrder (item, approve) {
      const params = createPostParams({
        action: 'processBlackPinOrder',
        payload: {
          activityId: item.activityId,
          operatorRemark: item.operatorRemark,
          approve: approve,
        },
      })

      try {
        await this.$rest.post('postActivity.php', params)
        this.$nextTick(() => {
          item.status = approve ? 'success' : 'decline'
        })
      } catch (e) {
        this.$nextTick(() => {
          // TODO: handle error
        })
      }
    },
  },
}
</script>
